<template>
    <PageTable formclass="User/form" endpoint="users" inlineToggle :aditionalColumns="headers" hideDelete>
        <template>
            <h1>Usuários</h1>
            <v-divider class="mb-4" />
        </template>
    </PageTable>
</template>

<script>
import PageTable from "@/components/core/PageTable";
export default {
    components: {
        PageTable,
    },
    data() {
        return {
            headers: [
                { text: this.$t("Habilitado"), value: "enable", align: "right", sortable: false },
                { text: this.$t("Email"), value: "email", align: "right", sortable: true },
                { text: this.$t("Nome"), value: "name", align: "right", sortable: true },
            ],
        };
    },
};
</script>